import React from 'react'
import { Link } from 'react-router-dom'
import 'animate.css';

export default function WhatWeDo() {
    return (
        <section id="about" className="dark_bg section_padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="single_about wow fadeInLeft">
                            <div className="about_title  animate__animated animate__fadeInLeft">
                                <span>What we do</span>
                                <h2>What can we do title</h2>
                                <p className="description">
                                    <span>FERME GOURMANDE</span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores itaque dolorem nobis deleniti veritatis autem assumenda aperiam facilis provident. Veniam inventore quisquam porro deserunt eius atque ad minima delectus molestiae.
                                </p>
                            </div>
                            <Link to="/about" className="main_btn  animate__animated animate__fadeInUp">Read More</Link>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                        <div className="single-about wow fadeInRight  animate__animated animate__fadeInRight">
                            <img 
                            style={{
                                borderRadius: '10px'
                            }}
                            src="https://dummyimage.com/720x480/f5e7f5/000000&text=720x480" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}