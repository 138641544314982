import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import 'animate.css';
import './style.css';

const HomeBanner: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true, // Adding fade effect for smoother transitions
    cssEase: 'linear'
  };

  return (
    <section id="home" className="parallax_banner"
      style={{ paddingTop: 50 }}
    >
      <div className="welcome-slider-area carousel-container">
        <Slider {...settings}>
          <div className="single-slide-item carousel-slide slide-one"
          >
            <div className="single-slide-item-table">
              <div className="single-slide-item-tablecell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 banner-container">
                      <h2 className="text-4xl font-bold animate__animated animate__fadeInDown">
                        TITLE ONE
                      </h2>
                      <h1 className="text-6xl font-extrabold animate__animated animate__fadeInLeft">
                        SUBTITLE ONE <span>SUBTITLE ONE</span>
                      </h1>
                      <p className="text-lg animate__animated animate__fadeInRight">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo tenetur quod, voluptatum debitis sit corrupti optio rerum ex, nihil porro quia cumque quis error
                      </p>
                      <div className="flex space-x-4 animate__animated animate__fadeInUp">
                        <Link to="/about" className="slider_btn s_bg_btn">
                          Read More
                        </Link>
                        <Link to="/contact" className="slider_btn">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-slide-item carousel-slide slide-two">
            <div className="single-slide-item-table">
              <div className="single-slide-item-tablecell">
                <div className="container">
                  <div className="row  banner-container-align-right">
                    <div className="col-md-12 banner-container-right">
                      <h2 className="text-4xl font-bold animate__animated animate__fadeInDown">
                        TITLE TWO
                      </h2>
                      <h1 className="text-6xl font-extrabold animate__animated animate__fadeInLeft">
                        SUBTITLE TWO <span>SUBTITLE TWO</span>
                      </h1>
                      <p className="text-lg animate__animated animate__fadeInRight">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                      </p>
                      <div className="flex space-x-4 animate__animated animate__fadeInUp">
                        <Link to="/about" className="slider_btn s_bg_btn">
                          Read More
                        </Link>
                        <Link to="/contact" className="slider_btn">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-slide-item carousel-slide slide-three">
            <div className="single-slide-item-table">
              <div className="single-slide-item-tablecell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 banner-container">
                      <h2 className="text-4xl font-bold animate__animated animate__fadeInDown">
                        TITLE THREE
                      </h2>
                      <h1 className="text-6xl font-extrabold animate__animated animate__fadeInLeft">
                        SUBTITLE THREE <span>SUBTITLE THREE</span>
                      </h1>
                      <p className="text-lg animate__animated animate__fadeInRight">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo tenetur quod, voluptatum debitis sit corrupti optio rerum ex, nihil porro quia cumque quis error
                      </p>
                      <div className="flex space-x-4 animate__animated animate__fadeInUp">
                        <Link to="/about" className="slider_btn s_bg_btn">
                          Read More
                        </Link>
                        <Link to="/contact" className="slider_btn">
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default HomeBanner;
