import React from 'react'
import { Link } from 'react-router-dom'

function ResentBlog() {
    return (
        <section id="blog" className="dark_bg section_padding">
            <div className="container">
                <div className="section_heading text-center wow zoomIn">
                    <h2>Latest <span>Blog</span></h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste eligendi quod
                    </p>
                    <div className="section_heading_border">
                    </div>
                </div> {/* END HEADING */}

                <div className="blog_area wow fadeInUp">
                    <div className="row">
                        {/* Blog Post 1 */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="post-slide">
                                <div className="post-img">
                                    <img src="https://dummyimage.com/1080x720/f5e7f5/000000&text=1080x720" alt="Blog Post 1" style={{ height: '245px' }} />
                                </div>
                                <div className="post-review">
                                    <a href="#">
                                        <h3 className="post-title">The First blog</h3>
                                    </a>
                                    <ul className="post-bar">
                                        <li><i className="fa fa-calendar"></i>Jul 30, 2023</li>
                                    </ul>
                                    <p className="post-description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat molestiae reprehenderit quod eaque. Mollitia fugit quibusdam repellat maxime sit corporis quo porro? Corrupti a sapiente nostrum ut facere amet esse!
                                    </p>
                                    <Link
                                        to='/about'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="read"
                                    >
                                        read more<i className="fa fa-angle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* Blog Post 2 */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="post-slide">
                                <div className="post-img">
                                    <img src="https://dummyimage.com/1080x720/f5e7f5/000000&text=1080x720" alt="Blog Post 2" style={{ height: '245px' }} />
                                </div>
                                <div className="post-review">
                                    <a href="#">
                                        <h3 className="post-title">The second blog</h3>
                                    </a>
                                    <ul className="post-bar">
                                        <li><i className="fa fa-calendar"></i>Jul 6, 2023</li>
                                    </ul>

                                    <p className="post-description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat molestiae reprehenderit quod eaque. Mollitia fugit quibusdam repellat maxime sit corporis quo porro? Corrupti a sapiente nostrum ut facere amet esse!
                                    </p>
                                    <Link
                                        to='/about'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="read"
                                    >
                                        read more<i className="fa fa-angle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* Blog Post 3 */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="post-slide">
                                <div className="post-img">
                                    <img src="https://dummyimage.com/1080x720/f5e7f5/000000&text=1080x720" alt="Blog Post 3" style={{ height: '245px' }} />
                                </div>
                                <div className="post-review">
                                    <a href="#">
                                        <h3 className="post-title">The third blog</h3>
                                    </a>
                                    <ul className="post-bar">
                                        <li><i className="fa fa-calendar"></i>May 11, 2023</li>
                                    </ul>
                                    <p className="post-description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat molestiae reprehenderit quod eaque. Mollitia fugit quibusdam repellat maxime sit corporis quo porro? Corrupti a sapiente nostrum ut facere amet esse!
                                    </p>
                                    <Link
                                        to='/about'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="read"
                                    >
                                        read more<i className="fa fa-angle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div> {/* END ROW */}
                </div> {/* END AREA */}
            </div> {/* END CONTAINER */}
        </section>
    )
}

export default ResentBlog