import React from 'react';
import HomeBanner from './components/HomeBanner';
import WhatWeDo from './components/WhatWeDo';
import OurServices from './components/OurServices';
import ResentPost from './components/ResentPost';
import ResentBlog from './components/ResentBlog';
import Footer from '../../components/Footer';

const HomeScreen = () => {
  return (
    <div>
      <HomeBanner />
      <WhatWeDo />
      <OurServices />
      <ResentPost />
      <ResentBlog />
      <Footer />
    </div>
  );
};

export default HomeScreen;
