import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from '../screens/HomeScreen';
import ContactScreen from '../screens/ContactScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import AppNav from '../components/nav';

const AppRoute = () => {
  return (
    <Router>
      <AppNav />
      <div>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </div>
    </Router>
  );
};

export default AppRoute;
