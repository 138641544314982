import React from 'react'
import { Link } from 'react-router-dom'

function ResentPost() {
    return (
        <div>
            <section id="portfolio" className="dark_bg section_padding">
                <div className="container">
                    <div className="section_heading text-center wow zoomIn">
                        <h2>Our <span>recents posts</span></h2>
                        <p>Follow us on social media</p>
                        <div className="section_heading_border">
                        </div>
                    </div> {/* END HEADING */}

                    <div className="grid_item_area">
                        <div className="col-md-4 col-sm-6 element-item expert padding_0">
                            <div className="box">
                                <img src="https://dummyimage.com/1080x1080/f5e7f5/000000&text=1080x1080" alt="Recent Post 1" style={{ height: '300px' }} />
                                <div className="box-content">
                                    <h3 className="title">Post one link</h3>
                                    <ul className="icon">
                                        <li>
                                            <Link
                                                to='/about'
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fa fa-link"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> {/* END COL */}

                        <div className="col-md-4 col-sm-6 element-item expert consult padding_0">
                            <div className="box">
                                <img src="https://dummyimage.com/1080x1080/f5e7f5/000000&text=1080x1080" alt="Recent Post 2" style={{ height: '300px' }} />
                                <div className="box-content">
                                    <h3 className="title">Post two link</h3>
                                    <ul className="icon">
                                        <li>
                                            <Link
                                                to='/about'
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fa fa-link"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> {/* END COL */}

                        <div className="col-md-4 col-sm-6 element-item research padding_0">
                            <div className="box">
                                <img src="https://dummyimage.com/1080x1080/f5e7f5/000000&text=1080x1080" alt="Recent Post 3" style={{ height: '300px' }} />
                                <div className="box-content">
                                    <h3 className="title">Post three link</h3>
                                    <ul className="icon">
                                        <li>
                                            <Link
                                                to='/about'
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fa fa-link"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> {/* END COL */}
                    </div>
                </div> {/* END CONTAINER */}
            </section>
            <section
                id="counter_area"
                style={{
                    backgroundImage: 'url(https://dummyimage.com/1920x1278/f5e7f5/000000&text=1920x1278)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundAttachment: 'fixed'
                }}
            >
                <div className="count_overlay">
                    <div className="container">
                        <div className="counter-content text-center">
                            <div className="row">
                                <div className="col-md-3 col-sm-6">
                                    <div className="count">
                                        <h3><span className="counter">350</span>+</h3>
                                        <h5>CERTIFIED INVESTIGATORS</h5>
                                    </div>
                                </div> {/* END COL */}

                                <div className="col-md-3 col-sm-6">
                                    <div className="count">
                                        <h3><span className="counter">24</span>K+</h3>
                                        <h5>ADMINISTERED INVESTIGATIONS</h5>
                                    </div>
                                </div> {/* END COL */}

                                <div className="col-md-3 col-sm-6">
                                    <div className="count">
                                        <h3><span className="counter">42</span>+</h3>
                                        <h5>LOCALITIES CONCERNED</h5>
                                    </div>
                                </div> {/* END COL */}

                                <div className="col-md-3 col-sm-6">
                                    <div className="count">
                                        <h3><span className="counter">16</span>+</h3>
                                        <h5>TARGETED SECTORS OF ACTIVITY</h5>
                                    </div>
                                </div> {/* END COL */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResentPost