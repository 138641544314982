import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.jpg';
import './style.css';

const AppNav = () => {
  const location = useLocation();
  const currentUrl = location.pathname;
  // const [active, setActive] = useState("home")
  //   console.log(currentUrl);

  // State to manage the class
  const [isActive, setIsActive] = useState(false);

  // Function to toggle the class
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  return (
    <header className="header_area" style={{ position: 'fixed', zIndex: 1000, width: '100%' }}>
      <div className="main_header navbar navbar-default menu-top dark_bg_menu"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-2 col-xs-4">
              <div className="logo">
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt="Logo" className="logo-no-bg" />
                </Link>
                <div className="mobile-nav" />
              </div>
            </div>

            <div className="col-md-10 col-sm-10 col-xs-8">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  onClick={toggleClass}
                >
                  <span className="fa fa-navicon hamburger-menu" />
                </button>
              </div>

              <nav id="navbar" className={`navbar-collapse collapse ${isActive ? 'in responsive-nav' : ''}`}>
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <Link
                      to="/"
                      className={`${currentUrl === '/' ? 'active-nav' : ''}`}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className={`${currentUrl === '/contact' ? 'active-nav' : ''}`}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppNav;
