import React from 'react'
import { Link } from 'react-router-dom'

export default function OurServices() {
    return (

        <section id="service" className="dark_bg section_padding">
            <div className="container">
                <div className="section_heading text-center wow zoomIn">
                    <h2>
                        How <span>Can We</span> Help ?
                    </h2>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores expedita minimsimilique ea sed?
                    </p>

                    <div className="section_heading_border">
                    </div>
                </div> {/* END HEADING */}

                <div className="row text-center">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="single-service wow fadeInLeft">
                                <i className="fa fa-shopping-cart"></i>
                                <h4>Service One</h4>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi sequi quis! Cupiditate praesentium in sed, modi distinctio quam magnam dolor odio quaerat enim incidunt assumenda expedita tempora placeat harum?
                                </p>
                            </div>
                        </div> {/* End Col */}

                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="single-service wow fadeInLeft">
                                <i className="fa fa-bullhorn"></i>
                                <h4>Service Two</h4>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi sequi quis! Cupiditate praesentium in sed, modi distinctio quam magnam dolor odio quaerat enim incidunt assumenda expedita tempora placeat harum?
                                </p>
                            </div>
                        </div> {/* End Col */}
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="single-service wow fadeInDown">
                                <i className="fa fa-thumbs-up"></i>
                                <h4>Service Three</h4>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi sequi quis! Cupiditate praesentium in sed, modi distinctio quam magnam dolor odio quaerat enim incidunt assumenda expedita tempora placeat harum?
                                </p>
                            </div>
                        </div> {/* End Col */}

                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="single-service wow fadeInRight">
                                <i className="fa fa-cogs"></i>
                                <h4>Service Four</h4>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi sequi quis! Cupiditate praesentium in sed, modi distinctio quam magnam dolor odio quaerat enim incidunt assumenda expedita tempora placeat harum?
                                </p>
                            </div>
                        </div> {/* End Col */}
                    </div>

                    <div>
                        <div className="pricingTable-sign-up">
                            <Link
                                style={{ marginTop: '15px' }}
                                target="_blank"
                                rel="noopener noreferrer"
                                to="/about"
                                className="main_btn"
                            >
                                Request a quote
                            </Link>
                        </div>
                    </div>
                </div>
            </div> {/* END CONTAINER */}
        </section>
    )
}
