import React from 'react'

import Logo from '../../assets/images/logo.jpg';

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <img src={Logo} alt="Qualytis Logo" className="logo-footer" />
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus eius quis sit. Eius, assumenda. Exercitationem repellendus explicabo nostrum magni, iure maxime esse, dolorem suscipit!
        </p>
        <ul className="socials">
          <li>
            <a target="_blank" rel="noopener noreferrer" href="">
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="">
              <i className="fa fa-whatsapp"></i>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="">
              <i className="fa fa-linkedin-square"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer